import React from 'react';
import { Link } from "gatsby";
import Slider from "react-slick";
import HeadlineHome01 from "../../img/HeadlineHome01.jpg";
import HeadlineHome02 from "../../img/HeadlineHome02.jpg";
import HeadlineHome03 from "../../img/HeadlineHome03.jpg";
import HeadlineHome04 from "../../img/HeadlineHome04.jpg";
import HeadlineHome05 from "../../img/HeadlineHome05.jpg";
import HeadlineHome06 from "../../img/HeadlineHome06.jpg";
import HeadlineHome07 from "../../img/HeadlineHome07.jpg";
import "./WaverChickenGrid.scss";

const SimpleSlider = (props) => {
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 3000,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const imgCarousel = [
    {
      img: HeadlineHome01,
      alt: 'HeadlineHome01'
    },
    {
      img: HeadlineHome02,
      alt: 'HeadlineHome02'
    },
    {
      img: HeadlineHome03,
      alt: 'HeadlineHome03'
    },
    {
      img: HeadlineHome04,
      alt: 'HeadlineHome04'
    },
    {
      img: HeadlineHome05,
      alt: 'HeadlineHome05'
    },
    {
      img: HeadlineHome06,
      alt: 'HeadlineHome06'
    },
    {
      img: HeadlineHome07,
      alt: 'HeadlineHome07'
    }
  ];

  return (
    <div>
      <Slider {...settings}>
        {
          imgCarousel.map((image) => (
            <div key={image.alt}>
              <img 
                className="mainGrid__banner" 
                src={image.img}
                alt={image.alt}
              />
            </div>
          ))
        }
      </Slider>
    </div>
  );
}

const ContactUS = () => {
  return (
    <div className="contactUs">
      <p>
        <b>Contact us today</b>
        <br></br>
        Do you have a question or comment? Let us know.
      </p>

      <p>
        <b>Write to us:</b>
        <br></br>
        Weaver Products
        <br></br> 
        Consumer Affairs Dept.
        <br></br>P.O. Box 2020
        <br></br>Springdale, AR 72762
      </p>

      <p>
        <b>Telephone:</b>
        <br></br>
        1-800-233-6332
      </p>

      <p>We appreciate your comments!</p>
    </div>
  );
}

const WaverChickenGrid = ({ children, isProductDetail=false, productData=null }) => {
  return (
    <>
      <div className="mainGrid">
        <div className="mainContent">
          <SimpleSlider/>

          <div className="info">
            {children}
          </div>
        </div>

        <div className="aside">
          {
            isProductDetail &&
            <div className="productImage d-md-none">
              <img
                src={productData.image}
                alt={productData.imageAlt}
              />  
            </div>
          }

          <Link className="product menuIdeas" to={'/menu-ideas'} >
            <div className="content">
              <h4>Menu Ideas</h4>  
            </div>
          </Link>

          {
            isProductDetail ?
            <div className="productImage d-none d-md-block">
              <img
                src={productData.image}
                alt={productData.imageAlt}
              />  
            </div>
            :
            <Link className="product products" to={'/products'} >
              <div className="content">
                <h4>Products</h4>  
              </div>
            </Link>
          }

          <ContactUS/>
        </div>
      </div>
    </>
  );
}

export default WaverChickenGrid;
